<template>
  <div>
    <slot :color-schema="colorSchema" />
  </div>
</template>

<script setup>
const props = defineProps({
    colorSchema: {
        type: String,
        required: true,
    },
});

const colorSchema = ref(props.colorSchema);
provide('colorSchema', colorSchema);
</script>
