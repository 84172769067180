/* eslint-disable import/no-unresolved */
import 'swiper/css';

import {
    Virtual, Pagination, Autoplay, Navigation, Grid, EffectFade,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

export default () => ({
    Swiper,
    SwiperSlide,
    Navigation,
    Virtual,
    Pagination,
    Autoplay,
    Grid,
    EffectFade,
});
